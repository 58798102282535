import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../App.css";
import certificate from "../assets/images/Certificate.png";
import neeladri from "../assets/images/NEELADRI_DHAR.jpg";
import saikat from "../assets/images/SAIKAT_SARKAR.jpg";
import sayan from "../assets/images/SAYAN_MAJUMDER.jpg";
import somwrik from "../assets/images/SOMWRIK_DUBEY.jpg";
import swarnalim from "../assets/images/swarnalim_sonowal.png";
import interview from "../assets/images/undraw_interview.png";
import wdctCorner from "../assets/images/WDCTCorner.png";

function LearnMore() {
  const coordinators = [
    {
      name: "Somwrik Dubey",
      img: somwrik,
      be: "https://www.behance.net/reventonhuracan",
      li: "https://www.linkedin.com/in/somwrik-dubey-8b35771ba/?originalSubdomain=in",
    },
    {
      name: "Swarnalim Sonowal",
      img: swarnalim,
      li: "https://www.linkedin.com/in/swarnalim-sonowal-77baa120a/?originalSubdomain=in",
    },
    {
      name: "Neeladri Dhar",
      img: neeladri,
      li: "https://www.linkedin.com/in/neeladri-dhar-11a7b220b/?originalSubdomain=in",
    },
    {
      name: "Saikat Sarkar",
      img: saikat,
      li: "https://www.linkedin.com/in/saikat-sarkar-395785205/?originalSubdomain=in",
    },
  ];
  return (
    <>
      <div className="learn container pb-5">
        <div id="desc" className="description">
          <h2 className="pb-3">Description</h2>
          <p className="descTxt pb-3">
            This Design Workshop organised by Web, Design and Creative Team, CCA
            brings to you 2 days full of learning and fun. Learn basics, tools
            and special features of Adobe Illustrator and After Effects.
          </p>
        </div>
        <div className="details">
          <div className="day">
            <p className="descTxtday pe-5">Day1: </p>
            <p>
              Learn Basic Tools, effects and special features. Create characters
              of your own and a simple background.
            </p>
          </div>
          <div className="day">
            <p className="descTxtday pe-5">Day2: </p>
            <p>Learn how to animate a simple character in After Effects.</p>
          </div>
        </div>
        <div className="description py-5">
          <h2 className="pb-3">What’s in for you</h2>
          <p>
            Apart from learning the basics of design theory, you get to know
            more about WDCT, CCA. Every participant will be awarded with a
            certificate, the work of the best performers will be featured in
            WDCT Corner and the performers would stand an advantage in the
            auditions!
          </p>
        </div>
        <Row className="">
          <div className="col-lg-4 my-1">
            <Card className="card">
              <Card.Img
                variant="top"
                className="certificate"
                src={certificate}
              />
              <Card.Body>
                <Card.Title align="center">Certificate</Card.Title>
                <Card.Text align="center">
                  of participation for all the participants
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-4 my-1">
            <Card className="card">
              <Card.Img className="wdctCorner" variant="top" src={wdctCorner} />
              <Card.Body>
                <Card.Title align="center">Top Entries</Card.Title>
                <Card.Text align="center">
                  will be featured in WDCT corner.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-4 my-1">
            <Card className="card">
              <Card.Img variant="top" className="interview" src={interview} />
              <Card.Body>
                <Card.Title align="center">Top Performers</Card.Title>
                <Card.Text align="center">
                  will get an advantage in auditions
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Row>
        <div id="mentor" className="description pt-5 pb-5">
          <h2>Mentor</h2>
        </div>
        <Row className="mentor">
          <Col sm={5} md={6} className="mentor1">
            <Row>
              <Col sm={5} md={6}>
                <img className="mentorImg" src={sayan} />
              </Col>
              <Col md={6} className="mentorTxt">
                <h3 className="pt-2 pb-2">Sayan Majumder</h3>
                <h4 className="pb-2">Head, WDCT, CCA</h4>
                <h3 className="mentorLinks">
                  <a
                    href="https://www.linkedin.com/in/sayan-majumder-6669171a5"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="fa-brands me-1 fa-linkedin"
                  />{" "}
                  <a
                    href="https://www.behance.net/kronocreate/projects"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="fa-brands ms-1 fa-behance"
                  />{" "}
                </h3>
              </Col>
            </Row>
          </Col>
          <Col sm={7} md={6} className="mentor2">
            <p className="pt-2">
              Sayan Majumder is a B. Tech. final year student at the National
              Institute of Technology, Durgapur and the head of the Web, Design,
              and Creative Team, CCA. He is a really gracious and modest person,
              very focused and devoted to his career and work. No one is in the
              dark about his design abilities.
            </p>
            <p>
              Sayan, is a UXR intern at Hike and a former intern at the esteemed
              IIT Delhi. With great design abilities, he has consistently shown
              himself to be the ideal person to seek counsel from. He is a
              testament to his abilities and a source of pride for everyone. The
              young man has shown himself to be an excellent teacher.
            </p>
          </Col>
        </Row>
        <div id="coordinator" className="description pt-5 pb-5">
          <h2>Coordinators</h2>
        </div>
        <Row>
          {coordinators.map((coordinator) => {
            return (
              <Col sm={12} md={6} lg={3}>
                <Card className="coordinatorCard pb-2">
                  <Card.Img
                    variant="top"
                    className="coordinatorImg"
                    src={coordinator?.img}
                  />
                  <Card.Body>
                    <Card.Title align="center" className="coordinatorName">
                      {coordinator?.name}
                    </Card.Title>
                    <Card.Text align="center">
                      Senior Member
                      <br />
                      WDCT, CCA
                    </Card.Text>
                    <Card.Title align="center">
                      <h4>
                        {coordinator?.li && (
                          <a
                            href={coordinator?.li}
                            class="fa-brands mx-1 fa-linkedin"
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        )}
                        {coordinator?.be && (
                          <a
                            href={coordinator?.be}
                            class="fa-brands mx-1 fa-behance"
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        )}
                      </h4>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
        <div className="my-5"></div>
      </div>
    </>
  );
}

export default LearnMore;
